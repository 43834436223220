import { template as template_99e8bfa4bde64f6fb50d23b22920a34e } from "@ember/template-compiler";
const FKLabel = template_99e8bfa4bde64f6fb50d23b22920a34e(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
