import { template as template_f4dfa907c2fa484ba563b38c5c58d53c } from "@ember/template-compiler";
const WelcomeHeader = template_f4dfa907c2fa484ba563b38c5c58d53c(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
