import { template as template_e2c9711b535c4809bbb45c94335c3c76 } from "@ember/template-compiler";
const SidebarSectionMessage = template_e2c9711b535c4809bbb45c94335c3c76(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
